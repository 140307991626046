import React from "react";

const App = () => {
  return (
    <>
      
      <div className="bg-dark text-light">
        <div className="container py-3">
          <h1>Aamir Rayeen</h1>
          <div className="bg-success my-3" style={{ minHeight: "10px" }}></div>
          <div className="my-3">
            Shree Nagar Extension, Anoop Nagar,
            <br /> Indore(M.P.),452011
            <br />
            <strong>Contact No: </strong>+919755405967
            <br />
            <strong>Email: </strong> aamir.rayeen.565@gmail.com
          </div>
          <div className="my-3 col-md-6">
            <h3 className="text-success fw-bold">CAREER OBJECTIVE</h3>
            <p>
              To utilize my technical skills to achieve the target and develop
              the best performance in the esteemed organization. I want to
              implement my innovative ideas, skills, and creativity to
              accomplish the goals.
            </p>
          </div>
          <div className="my-3 col-md-6">
            <h3 className="text-success fw-bold">WORK EXPERIENCE</h3>
            <ul>
              <li>
                Customer Care Executive - Teleperformance India - (December 2022
                - Present)
                <ul>
                  <li>Answered customer inquiries via chat.</li>
                  <li>Resolved customer complaints and issues.</li>
                  <li>
                    Maintained customer records and updated account information.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="my-3 col-md-6">
            <h3 className="text-success fw-bold">QUALIFICATION</h3>
            <ul>
              <li>
                <span className="fw-bold">
                  SANDEEPANI PUBLIC H.S. SCHOOL, MORENA
                </span>{" "}
                -{" "}
                <span className="fst-italic">
                  HIGHER SECONDARY ( M.P. BOARD)
                </span>
              </li>
              <li>
                <span className="fw-bold">
                  INDRAPRASTHA ARTS, SCIENCE & MANAGEMENT INSTITUTE KAILARAS,
                  MORENA
                </span>
                <span className="fst-italic">
                  - Bachelor of Science ( Computer Science)
                </span>
              </li>
              <li>
                <span className="fw-bold">
                  A. S. DEGREE COLLAGE KAILARAS, MORENA
                </span>
                <span className="fst-italic">- PGDCA</span>{" "}
              </li>
            </ul>
          </div>
          <div className="my-3 col-md-6">
            <h3 className="text-success fw-bold">KEY SKILLS</h3>
            <p>
              <strong>Languages:-</strong> HTML, CSS, JavaScript, ES-6{" "}
            </p>
            <p>
              <strong>Client side:-</strong> React JS, Redux{" "}
            </p>
            <p>
              <strong>Server side:-</strong> Node JS, Express MVC, RESTful API{" "}
            </p>
            <p>
              <strong>Database:-</strong> MongoDB, Mongoose{" "}
            </p>
            <p>
              <strong>Other:-</strong> Git, Postman, JWT, Login Auth, VS code,
              CLI{" "}
            </p>
          </div>
          <div className="my-3 col-md-6">
            <h3 className="text-success fw-bold">STRENGTH </h3>
            <ul>
              <li>Good communication skills and Gentleness.</li>
              <li>
                Honest, Smart Working, Responsible, Punctual, and energetic.{" "}
              </li>
            </ul>
          </div>
          <div className="my-3 col-md-6">
            <h3 className="text-success fw-bold">PERSONAL DETAILS </h3>
            <ul>
              <li>
                <span className="fw-bold">FATHER NAME: </span>MOHAMMAD YUSUF
                RAYEEN
              </li>
              <li>
                <span className="fw-bold">DATE OF BIRTH: </span>25/12/1998{" "}
              </li>
              <li>
                <span className="fw-bold">NATIONALITY: </span>INDIAN{" "}
              </li>
              <li>
                <span className="fw-bold">MARITAL STATUS: </span>UNMARRIED{" "}
              </li>
              <li>
                <span className="fw-bold">LANGUAGES: </span>HINDI & ENGLISH{" "}
              </li>
              <li>
                <span className="fw-bold">HOBBIES: </span>CYCLING, READING
                BOOKS, COOKING, COIN COLLECTING.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-dark text-center text-light py-2 fw-bold">
        Declaration- All the above information given is true to my knowledge.
      </div>
    </>
  );
};

export default App;
